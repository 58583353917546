<template>
  <section class="container">
    <div class="register"
         :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
      <h3 class="title">無料会員登録</h3>

      <div class="register__container">
        <!--Step-->
        <StepTabs/>

        <!--Register-->
        <router-view/>
      </div>

      <!--list pickup-->
      <ListPickup v-if="$route.name === 'RegisterComplete'"
                  :list="pickupList"
                  class="regis-pickup"/>
    </div>
  </section>
</template>

<script>
import StepTabs from '@/views/Register/StepTabs'
import ListPickup from '@/components/home/ListPickup'
import store from '@/store'
import { mapState } from 'vuex'
import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'

export default {
  name: 'Register',

  mixins: [Common],

  components: {
    ListPickup,
    StepTabs
  },

  computed: {
    ...mapState('pickup', ['pickupList']),
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  created () {
    store.dispatch('pickup/getList', { with: 'advertise' })

    if (this.checkWebview()) {
      !Cookie.get('bearer_token') ? this.openPageNative('Register') : this.openPageNative('Index')
    }
  }
}
</script>

<style lang="scss" scoped>
  .register {
    padding: 90px 0;
    @media #{$info-phone} {
      padding: 85px 0;
    }
    &.padding-has-caution {
    @media #{$info-phone} {
      padding: 110px 0 85px;
    }
  }
    &.padding-webview {
      padding: 40px 0 0;
    }
    .title {
      font-family: $font-family-title;
      color: $default-green;
      @include font-size(36px);
      font-weight: 400;
      text-align: center;
      margin-bottom: 40px;
      @media screen and (max-width: 768px) {
        font-size: 32px;
        margin-bottom: 30px;
      }
    }
    &__container {
      border: 6px solid #E7E5DE;
      border-radius: 6px;
      background-color: #FAFAFA;
      @media screen and (max-width: 768px) {
        border: 3px solid #E7E5DE;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }
    }
    .regis-pickup{
      margin-top: 85px;
      @media #{$info-phone} {
        padding: 0 18px;
        margin-top: 65px;
      }
    }
  }
  @media #{$info-phone} {
    .container {
      padding: 0;
    }
  }
</style>
