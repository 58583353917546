<template>
<div class="pick-up" v-if="list.length">
  <!-- block title pickup -->
  <div class="block-title">
    <h2>PICKUP</h2>
    <span>ピックアップ</span>
  </div>

  <!-- list pickup on pc -->
  <div class="list-pickup" v-if="list.length">
    <router-link v-for="(item, index) in list"
                 :to="{ name: 'AdvertisingDetail', params: { id: item.advertise_id || 1 } }"
                 :key="index"
                 class="block-pickup">

      <figure>
        <img :src="(item.advertise.file ? item.advertise.file.path : '') | imageEmpty" class="logo web">
      </figure>

      <!-- description content -->
      <div class="description-content">

        <h4 class="line-clamp-02">{{ item.advertise.title }}</h4>
        <p class="line-clamp-01" v-html="item.advertise.list_description"/>

        <div class="point-back">
          <!-- normal point before campaign -->
          <span v-if="item.advertise.is_campaign === 1
                    && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
            通常
            <del>
              <template v-if="item.advertise.point_type === 1">
                {{item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')}}
              </template>
              <template v-else>
                {{item.advertise.normal_pointback_rate | numberWithCommas(',', '%P')}}
              </template>
            </del>
          </span>

          <!-- after point -->
          <template>
            <template v-if="item.advertise.is_campaign === 1
                          && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
              <template v-if="item.advertise.point_type === 2">
                <strong>{{item.advertise.campaign_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
              </template>
              <template v-else >
                <strong>{{item.advertise.campaign_pointback_amount | numberWithCommas(',', 'P')}}</strong>
              </template>
            </template>
            <template v-else>
              <template v-if="item.advertise.point_type === 2">
                <strong>{{item.advertise.normal_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
              </template>
              <template v-else >
                <strong>{{item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')}}</strong>
              </template>
            </template>
          </template>
        </div>
      </div>
    </router-link>
  </div>

  <!-- list pick up on sp -->
  <VueSlickCarousel v-bind="settingSlide"
                    v-if="list.length"
                      class="slide-pickup sp">
    <div class="wrap-list"
         v-for="(cate) in list"
         :key="cate.id">

      <router-link :to="{ name: 'AdvertisingDetail', params: { id: cate.advertise_id || 1 } }"
                   class="block-pickup">
        <!-- img cate -->
        <figure>
          <img :src="(cate.file ? cate.file.path : '') | imageEmpty" class="logo web">
        </figure>

        <!-- description content -->
        <div class="description-content">
          <h4 class="line-clamp-02">{{ cate.title }}</h4>
          <p class="line-clamp-01" v-html="cate.list_description"/>

          <div class="point-back">
            <!-- normal point before campaign -->
            <span v-if="cate.is_campaign === 1
                      && checkDateBetween(cate.campaign_start_datetime, cate.campaign_end_datetime)">
            通常
            <template v-if="cate.point_type === 1">
              {{cate.normal_pointback_amount| numberWithCommas(',', 'P')}}
            </template>
            <template v-else>
              {{cate.normal_pointback_rate | numberWithCommas(',', '%P')}}
            </template>
          </span>
            <!-- after point -->
            <template>
              <template v-if="cate.is_campaign === 1
                             && checkDateBetween(cate.campaign_start_datetime, cate.campaign_end_datetime)">
                <template v-if="cate.point_type === 2">
                  <strong>{{cate.campaign_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
                </template>
                <template v-else >
                  <strong>{{cate.campaign_pointback_amount | numberWithCommas(',', 'P')}}</strong>
                </template>
              </template>
              <template v-else>
                <template v-if="cate.point_type === 2">
                  <strong>{{cate.normal_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
                </template>
                <template v-else >
                  <strong>{{cate.normal_pointback_amount | numberWithCommas(',', 'P')}}</strong>
                </template>
              </template>
            </template>
          </div>
        </div>
      </router-link>
    </div>

  </VueSlickCarousel>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Common from '@/mixins/common.mixin'

export default {
  name: 'ListPickup',

  components: {
    VueSlickCarousel
  },

  mixins: [Common],

  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      settingSlide: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        rows: 4,
        slidesPerRow: 1,
        dots: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.block-title {
  h3 {
    label {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: url("../../assets/images/icon/dot-under-line.svg") no-repeat;
        background-size: contain;
        background-position: center;
        top: 1px;
        left: 0;
      }
    }
  }
}
</style>
