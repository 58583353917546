<template>
  <ul class="tabs">
    <li v-for="tab in tabs"
        :key="tab.number"
        :class="{active: tab.routeName === $route.name}">
      <span v-text="tab.number + '／'"/>{{tab.text }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'StepTabs',

  data () {
    return {
      tabs: [
        {
          number: 1,
          text: 'アカウントのご登録',
          routeName: 'Register'
        },
        {
          number: 2,
          text: 'ご登録情報の入力',
          routeName: this.$route.name === 'RegisterSNS' ? 'RegisterSNS' : 'RegisterForm'
        },
        {
          number: 3,
          text: '完了',
          routeName: 'RegisterComplete'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  ul.tabs {
    display: flex;
    @media screen and (max-width: 768px) {
      border-bottom: 1px solid #CACBC6;
      padding: 0 18px;
      justify-content: center;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #282828;
      @include font-size(16px);
      width: calc(100%/3);
      padding: 4px 0;
      border-bottom: 2px solid #CACBC6;
      font-family: $font-family-title;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        width: auto;
        padding: 14px 12px;
        border-bottom: 5px solid transparent;
      }
      span {
        @include font-size(24px);
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    li.active {
      color: #7C934C;
      border-bottom: 5px solid $default-green;
    }
  }
</style>
